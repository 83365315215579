import React from 'react';
import { Modal, Button, Card, Form, Input, Alert } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const FormItem = Form.Item;
const { TextArea } = Input;

class Notes extends React.Component {
  state = {
    putREQUEST: false,
    putFAILURE: false,
    putSUCCESS: false,
    putMessage: '',
    getListRequest: false,
    listNotes: []
  }
  componentDidMount() {
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      this.setState({
        putREQUEST: true
      });
      const { sessionId } = this.props;
      fetchJSON(`/api/v2/machines/testingNote/${sessionId}`, {
        method: 'PUT',
        body: {
          note: fieldsValue['note']
        }
      }).then(response => {
        console.log(response);
        if (response.success) {
          this.setState({
            putREQUEST: false,
            putSUCCESS: true,
            putFAILURE: false,
            putMessage: response.message
          });
        } else {
          this.setState({
            putREQUEST: false,
            putFAILURE: true,
            putSUCCESS: false,
            putMessage: response.message
          });
        }
        setTimeout(() => {
          this.setState({
            putMessage: ''
          });
        }, 3000)
       }).catch(error => {
          console.log(error);
          this.setState({
            putSUCCESS: false,
            putREQUEST: false,
            putFAILURE: true,
          });
       });
    })
  }
  render() {
    const { putREQUEST, putMessage, putSUCCESS } = this.state;
    const { visible, form, source, sourceId } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Modal
        className="nocardPadding"
        style={{ top: 20 }}
        width={600}
        visible={ visible }
        title={`Notes for ${source} ${sourceId}`}
        onOk={this.props.handleClose}
        onCancel={this.props.handleClose}
        footer={[
          <Button key="submit" type="primary" onClick={this.props.handleClose}>
            Ok
          </Button>,
        ]}
      >
        <Card title="New note" loading = { putREQUEST }>
           <Form layout="vertical" onSubmit={this.handleSubmit}>
            <FormItem label="Note">
              {getFieldDecorator('note', {
                rules: [{ required: true, message: 'Please enter a note!' }],
              })(
                <TextArea />
              )}
            </FormItem>
            <Button type="primary" htmlType="submit">Submit</Button>
            { putMessage ? 
              <div style={{marginTop: '20px'}}><Alert message={putMessage} type={ putSUCCESS ? "success" : "error" } /></div>
            : null }
            </Form>
        </Card>
      </Modal>
    )
  }
};

export default Form.create()(Notes);