import React from 'react';
import Settings from 'services/config/Settings';
import { Form, Icon, Input, Button, Layout, Card, Alert, Spin } from 'antd';
import { Redirect } from 'react-router-dom';
import fetchJSON from 'services/utils/fetchJSON';
import qs from 'qs';
import Auth from 'services/utils/Auth';

const styleLayout = {
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center'
};
const FormItem = Form.Item;


class Login extends React.Component {
  state = {
    isFetching: false,
    serverMessage: '',
    serverMessageType: 'error',
    isAuthenticated: false,
    redirectUrl: '/',
    step: 1,
    phoneStorage: '',
    resendIsActive: true
  }
	componentDidMount = () => {
		document.title = `Login | ${Settings.title}`;
    if (window.location.search) {
      let param = window.location.search;
      let paramFromUrl = qs.parse(param, { ignoreQueryPrefix: true });
      let redirect = paramFromUrl.redirect ? paramFromUrl.redirect : '/';
      this.setState({
        redirectUrl: redirect
      })
    }
  }
  handleSubmit = (e = false) => {
    if (e) {
      e.preventDefault();
    }
    
    this.props.form.validateFields((err, creds) => {
      if (err) {
        console.log('err', err);
        return;
      }
      const { step, phoneStorage } = this.state;

      let stateTmp = {
        isFetching: true,
      };
      
      if (step === 1) {
        stateTmp.phoneStorage = creds.phone;
      }
      this.setState(stateTmp);

      if (step === 2) {
        creds.phone = phoneStorage;
      }

      creds.step = step;

      fetchJSON('/api/v2/auth/login', {
        method: 'POST',
        body: creds
      }).then(response => {
        console.log(response);
        let step = 1;
        if (response.step) {
          step = response.step;
        }
        this.setState({
          isFetching: false,
          serverMessage: response.message,
          serverMessageType: response.code > 300 ? 'error' : 'success',
          step,
          resendIsActive: false
        });
        if (response.code === 201) {
          setTimeout(() => {
            this.setState({
              resendIsActive: true
            });
          }, 5000); 
        }

        if (response.code === 200) {
          // If login was successful, set the token in cookie
          Auth.loginUser(response.token);
          this.setState({
            isAuthenticated: true
          });
        } else {
          setTimeout(() => {
            this.setState({
              serverMessage: ''
            });
          }, 3000);
        }
       }).catch(error => {
          console.log(error);
          this.setState({
            isFetching: false
          });
       });
    });
  }
  resendCode = (e) => {
    
    this.setState({
      step: 1
    }, () => this.handleSubmit());
  }
  resetPhone = (e) => {
    e.preventDefault();
    this.props.form.setFields({
      phone: {
        value: ''
      },
    });
    this.setState({
      step: 1,
      serverMessage: '',
      phoneStorage: ''
    });
  }
  render() {
    const {
      isFetching,
      serverMessage,
      isAuthenticated,
      serverMessageType,
      redirectUrl,
      step,
      resendIsActive
    } = this.state;

    const { getFieldDecorator } = this.props.form;
    return (
    	<Layout style={ styleLayout }>
        <Spin spinning={ isFetching } >
          <Card title={Settings.title} style={{ width: 350 }}>
            <Form onSubmit={this.handleSubmit} className="login-form">
              { serverMessage ? 
                <FormItem><Alert message={serverMessage} type={ serverMessageType } /></FormItem>
              : null }
              <FormItem>
                {getFieldDecorator('phone', {
                  rules: [{ required: true, message: 'Please input your phone number!' }],
                })(
                  <Input type="tel" prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Phone number" readOnly={step === 2} />
                )}
              </FormItem>
              {
                step === 2 ? 
                <React.Fragment>
                  <FormItem>
                    {getFieldDecorator('code', {
                      rules: [{ required: true, message: 'Please input your One Time Password!' }],
                    })(
                      <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="text" placeholder="One Time Password" />
                    )}
                  </FormItem>
                </React.Fragment>
                : null
              }
              { step === 2 ? <FormItem>
                <div style={{'display': 'flex', 'alignItems': 'center'}}>
                  <Button type="dashed" size="small" onClick={this.resetPhone} htmlType="button">
                    Reset phone number
                  </Button>
                  <Button type="dashed" size="small" onClick={this.resendCode} disabled={!resendIsActive} htmlType="button" style={{'marginLeft': 'auto'}}>
                    Request new code
                  </Button>
                </div>
              </FormItem>  : null }
              <FormItem>
                <Button type="primary" htmlType="submit" className="login-form-button">
                  Log in
                </Button>
              </FormItem>
            </Form>
          </Card>
        </Spin>
        { isAuthenticated ?  <Redirect to={ redirectUrl ? redirectUrl : '/' } /> : null }
      </Layout>
    )
  }
};

export default Form.create()(Login);