import React from 'react';
import { Router, Switch, Route} from 'react-router-dom';
// import { TrackJS } from 'trackjs';
// import Settings from 'services/config/Settings';
import App from './App';

import Search from 'screens/Search/Search';
import Testing from 'screens/Testing/Testing';

import LogOut from 'screens/LogOut';
import Login from 'screens/Login/Login';

// utils for check authorization status
import { checkLogin, checkAccessWrap } from 'services/utils/requireAuth';
// import AppWrapper from 'components/AppWrapper/AppWrapper';
import { createBrowserHistory as createHistory } from 'history'

// if (Settings.trackJsEnable) {
// 	TrackJS.install(Settings.trackJS);
// }

export const history = createHistory();

const PrivateRoute = ({ component: Component, isAuthenticated, redirectTo, ...rest}) => (
	<Route
		{...rest}
		render = { props => (
			isAuthenticated(redirectTo, () => {
				return ( <Component {...props} /> );
			})
		)}
	/>
);

const router = () => (
	<Router history={history}>
		<App>
			<Switch>
				<Route exact path='/testing/:sessionId' component={checkAccessWrap(['admin', 'tester'], Testing)} />
				<PrivateRoute exact path="/login" component={Login} isAuthenticated={checkLogin} redirectTo='/' />
				<Route exact path="/logout" component={LogOut} />
				<Route path='/' component={checkAccessWrap(['admin', 'tester'], Search)} />
			</Switch>
		</App>
	</Router>
);

export default router;
