import React from 'react';
import Settings from 'services/config/Settings';
import { Input, Card, Alert, Spin } from 'antd';
import fetchJSON from 'services/utils/fetchJSON';

const { Search } = Input;

class SearchPage extends React.Component {
  state = {
    serverMessage: '',
    serverMessageType: '',
    isFetching: false
  }
	componentDidMount = () => {
		document.title = `Search | ${Settings.title}`;
  }
  searchSupernova = (value) => {    
    this.setState({
      isFetching: true
    });

    fetchJSON('/api/v2/machines/search', {
      method: 'POST',
      body: {
        value
      }
    }).then(response => {
      console.log(response);
      this.setState({
        isFetching: false
      });
      if (response.code === 200) {
        this.props.history.push(`/testing/${response.session_id}`);
      } else {
        this.setState({
          isFetching: false,
          serverMessage: response.message,
          serverMessageType: response.code > 300 ? 'error' : 'success'
        });
      }
      
     }).catch(error => {
        console.log(error);
        this.setState({
          isFetching: false,
        });
     });
  }
  render() {
    const { serverMessage, serverMessageType, isFetching } = this.state;
    return (
      <Card title="Supernova Search" style={{ width: 350, margin: '0 auto'}}>
        <Spin spinning={ isFetching } >
          <Search
            placeholder="Enter Supernova ID"
            enterButton="Search"
            size="large"
            onSearch={this.searchSupernova}
          />
          { serverMessage ? 
            <Alert message={serverMessage} type={ serverMessageType } style={{marginTop: '20px'}} />
          : null }
        </Spin>
      </Card>
    )
  }
};

export default SearchPage;