import Cookies from 'js-cookie';

export const getUniqueKey = () => {
	if (Cookies.get('wheeler') === undefined) {
		const generate = require('nanoid/generate');
		const wheeler = generate('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 15);
		Cookies.set('wheeler', wheeler, { expires: 365 });
		return wheeler;
	} else {
		return Cookies.get('wheeler');
	}
}

//console.log('analytics file');
export default (event, params = {}) => {
	/* eslint-disable */
	let heapId = '';

	try {
		// console.log('analytics event', event);
		heap.track(event, params);
		const heapObj = JSON.parse(JSON.stringify(heap));
		heapId = heapObj.userId;
	} catch(e) { }	
	
}