import React from 'react';

class App extends React.Component {
  render() {
    return (
			<React.Fragment>
				{ this.props.children }
			</React.Fragment>
    )
  }
};

export default App;