import React from 'react';
import IdleTimer from 'react-idle-timer'
import Settings from 'services/config/Settings';
import {
  Layout,
  Spin,
  Card,
  Button,
  message,
  Alert,
  Descriptions,
  Modal
} from 'antd';
import fetchJSON from 'services/utils/fetchJSON';
import Notes from 'components/Notes/Notes';
import PrintLabel from './components/PrintLabel/PrintLabel';

import _ from 'lodash';

import './styles.less';

const { Content } = Layout;

const statusNest = {
  'BO': 'Nest BO',
  'BC': 'Nest BC',
  'EC': 'Empty Closed',
  'EO': 'Nest EO'
};

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
const confirm = Modal.confirm;
const buttonStyle = { margin: '0 8px 8px 0' };
const cardStyle = { marginBottom: '8px' };

const nests = [1,2,3,4,5,6,7];
const CHRG_STAT = ['Pre-charge', 'Fast Charging'];
const CHRG_STAT_charged = ['Charge Termination Done'];

class Testing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sessionId: '',
      serverMessage: '',
      serverMessageType: '',
      isFetching: false,
      objId: '',
      type: '',
      status: '',
      supernova: {},
      actualStatus: '',
      user_access_to: '',
      visibleNotes: false,
      visiblePrintLabel: false,
      seconds: 20,
      accessError: false,
      activeNest: 0,
    }
    this.idleTimer = null
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
  }
  
	componentDidMount = () => {
		document.title = `Testing | ${Settings.title}`;

    const { sessionId } = this.props.match.params;
    this.setState({
      sessionId
    }, this.getSessionStatus);
    this.startStatusInteval();
    this.timeoutStatusRequests = 0;
  }
  startStatusInteval = () => {
    clearTimeout(this.intervalID);
    this.intervalID = setInterval(() => {
      this.getSessionStatus(false);
    }, 15 * 1000);
  }
  componentWillUnmount() {
    clearTimeout(this.intervalID);
    clearInterval(this.timeoutStatusRequests);
  }
  startStatusTimeout = (secondsAmount = 20) => {
    this.setState({
      seconds: secondsAmount
    });
    clearTimeout(this.intervalID);
    this.timeoutStatusRequests = setInterval(this.countDown, 1000);
  }
  countDown = () => {
    const { seconds } = this.state;
    this.setState({
      seconds: seconds - 1
    });
    if (seconds < 1) {
      clearInterval(this.timeoutStatusRequests);
      this.startStatusInteval();
    }
  }
  getSessionStatus = (withFetching = true) => {
    console.log('getSessionStatus');
    const { sessionId } = this.state;
    if (withFetching) {
      this.setState({
        isFetching: true
      });
    }
    fetchJSON(`/api/v2/machines/testing/${sessionId}`, {
      method: 'GET'
    }).then(response => {
      if (withFetching) {
        this.setState({
          isFetching: false
        });
      }
      if (response.code === 200) {
        console.log(response.data);
        const { objId, type } = response.data;
        const state = {
          objId: objId,
          type: type
        };
        const supernovas = _.get(response, 'data.broker.supernovas');
        const actualStatus = _.get(response, 'data.supernova.actual_status');
      
        if (actualStatus) {
          state.actualStatus = actualStatus;
        }

        const user_access_to = _.get(response, 'data.user_access_to');
        if (user_access_to) {
          state.user_access_to = user_access_to;
        }
        
        if (supernovas) {
          if (Object.keys(supernovas).length) {
            if (Object.keys(supernovas).indexOf(objId) > -1) {
              state.supernova = supernovas[objId];
              state.status = state.supernova.connection;
            }
          } 
        }
        this.setState(state);
      } else {
        this.props.history.push('/');
      }
     }).catch(error => {
        console.log(error);
        //this.props.history.push('/');
     });
  }
  sendCommand = (command, callback = false) => {
    const { sessionId } = this.state;
    fetchJSON(`/api/v2/machines/testing/${sessionId}`, {
      method: 'POST',
      body: {
        command
      }
    }).then(response => {
      console.log('response', response);
      if (response.code === 200) {
        let seconds = 20;
        if (command.indexOf('release_pack_') > -1) {
          seconds = 40;
          if (command === 'release_pack_all') {
            seconds = 40 * 8;
          }
        }
        this.startStatusTimeout(seconds);
        message.success('OK');
      } else {
        message.error('Error');
      }
      if (callback) {
        callback();
      }
     }).catch(error => {
        console.log(error);
        message.error('Server Error');
     });
  }
  getNestElectronStatus = (nestId) => {
    const { supernova } = this.state;
    const electron = _.get(supernova, `electrons[${nestId}]`);
    const voltage = _.get(supernova, `voltages[${nestId - 1}]`);
    const status = _.get(electron, 'status.CHRG_STAT');
    let charging = '-';

    if (electron) {
      if (status) {
        if (CHRG_STAT.indexOf(status) > -1) {
          charging = 'Charging';
        } else if (CHRG_STAT_charged.indexOf(status) > -1) {
          charging = 'Charged';
        } else {
          charging = 'Not Charging';
        }
      }
      let cable_types = '';
      if (electron.cable_types === 'lightning_usb_c') {
        cable_types = 'TypeC';
      }
      if (electron.cable_types === 'lightning_micro_usb') {
        cable_types = 'Micro';
      }
      return {
        key: nestId,
        nestId,
        electron_id: electron.id,
        soc: electron.soc,
        cable_types: cable_types,
        status: charging
      };
    } else {
      let status = '';
      if (isNaN(voltage)) {
        status = statusNest[voltage] ? statusNest[voltage] : 'ERR';
      } else {
        status = `Voltage = ${voltage}`;
      }
      return status
    }
  }
  
  getNestStatus = (nestId) => {
    const { supernova } = this.state;
    const voltage = _.get(supernova, `voltages[${nestId - 1}]`);
    if (voltage) {
      let status = '';
      let type = 'info';
      if (isNaN(voltage)) {
        status = statusNest[voltage] ? statusNest[voltage] : 'ERR';
        if (['BO', 'EO', 'BC'].indexOf(voltage) > -1 || !statusNest[voltage]) {
          type = 'error';
        }
      }
      if (status) {
        return {
          type, 
          status,
          code: voltage
        };
      }
    }
    return false;
  }
  hideNotes = () => {
    this.setState({
      visibleNotes: false
    });
  }
  hidePrintLabel = () => {
    this.setState({
      visiblePrintLabel: false
    });
  }
  getSupenovasDescription = () => {
    const { supernova, actualStatus } = this.state;
    const data = {};
    if (supernova.signalRangeText) {
      data['Signal strength'] = supernova.signalRangeText;
    }
    if (supernova.group_id) {
      data['Location ID'] = supernova.group_id;
    }
    if (actualStatus) {
      data['Status'] = capitalize(actualStatus);
    }
    if (Object.keys(data).length) {
      return (
        <Descriptions bordered size="small" style={{marginBottom: '16px'}}>
          {Object.keys(data).map((key, i) => {
            return (<Descriptions.Item key={i} label={key}>{data[key]}</Descriptions.Item>)
          })}
        </Descriptions>
      );
    }
    return null;
  }
  showNotes = () => {
    this.setState({
      visibleNotes: true
    });
  }
  showPrintLabel = () => {
    this.setState({
      visiblePrintLabel: true
    });
  }
  startInventory = () => {
    this.setState({
      isFetching: true
    });
    this.sendCommand('run_inventory', () => {
      setTimeout(() => {
        this.getSessionStatus();
      }, 3000);
    });
  }
  fullyTested = () => {
    const { user_access_to } = this.state; 
    confirm({
      title: 'Confirm status change',
      content: user_access_to === 'factory' ? 'If you change your status to tested, you will lose access to this supernova.' : null,
      onOk: () => {
        return new Promise((resolve, reject) => {
          const { sessionId } = this.state;
          fetchJSON(`/api/v2/machines/testing/${sessionId}`, {
            method: 'PUT',
          }).then(response => {
            console.log('response', response);
            if (response.code === 200) {
              message.success('OK');
              this.getSessionStatus(false);
              resolve()
            } else {
              message.error('Error');
              reject();
            }
           }).catch(error => {
              console.log(error);
              reject();
              message.error('Server Error');
           });
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  }
  _onActive(e) {
    // console.log('user is active', e)
    if (this.intervalID === 0) {
      this.startStatusInteval();
    }
  }
  _onIdle(e) {
    console.log('user is idle');
    clearTimeout(this.intervalID);
    this.intervalID = 0;
  }
  releaseToPackAll = () => {
    this.sendCommand('run_inventory', () => {
      this.sendCommand('release_pack_all');
    });
  }
  renderNests = () => {
    const { activeNest, accessError } = this.state;

    const nestRender = [];
    nests.forEach((k, i) => {
      const elStatus = this.getNestElectronStatus(k);
      const nestStatus = this.getNestStatus(k);
      let nestError = false; 
      // console.log('nest', k, 'elStatus', elStatus, 'nestStatus', nestStatus);
      
      let nestState = '';
      if (nestStatus.type === 'error') {
        nestError = nestStatus.status;
      }
      
      if (typeof elStatus === 'object') {
        if (!elStatus.electron_id || elStatus.charging === 'Not Charging') {
          nestState = 'error';
        } else if (elStatus.soc > 90) {
          nestState = 'success';
        }
        elStatus.nestError = nestError;
      } 

      if (nestStatus.type === 'error') {
        nestState = 'error';
      }
      if (accessError) {
        nestState = 'error';
      }

      nestRender.push(
        <div key={i} className={`
          qcSupernova__nest
          qcSupernova__nest--${k}
          ${activeNest === k ? 'qcSupernova__nest--active' +(nestState? '-'+nestState : '') : '' }
          ${nestState ? `qcSupernova__nest--${nestState}` : '' }
          `}
          onClick={() => this.selectNest(k, elStatus)}>
          { typeof elStatus === 'object' ?
            <React.Fragment>
              {elStatus.soc > -1 ? <span
                className={"qcSupernova__soc " + (elStatus.soc < 21 ? 'qcSupernova__soc--low ' : '')  + (nestError ? 'moveUp' : '')}>
                { elStatus.soc > 90 ? 'Charged' : `${elStatus.soc}%` }
                </span> : null }
              {!elStatus.electron_id ? 
                <span className="qcSupernova__centerIssue">No Electron ID</span>
              : null }
              {elStatus.charging === 'Not Charging' ? 
                <span className="qcSupernova__centerIssue">No charging</span>
              : null }
              { elStatus.nestError ? 
                <span className="qcSupernova__centerIssue qcSupernova__centerIssue--bigger">{nestError}</span>
              : null }
              <span className={"qcSupernova__cableType " + (nestError ? 'moveDown' : '')}>{elStatus.cable_types}</span>
            </React.Fragment>
          : <span className="qcSupernova__centerIssue">{elStatus}</span> }
          </div>
        );
    });

    return nestRender;
  }
  selectNest = (nestId) => {
    const { activeNest } = this.state;

    this.setState({
      activeNest: activeNest !== nestId ? nestId : 0
    });
  }
  renderProblems = () => {
    let problems = [];
    const { accessError, objId } = this.state;

    const isCellularSupernova = objId && objId.length > 2 ? objId.substring(0, 2) === 'SC' : false;
     nests.forEach((k, i) => {
      const elStatus = this.getNestElectronStatus(k);
      const nestStatus = this.getNestStatus(k);

      let nestError = false; 
      // console.log('nest', k, 'elStatus', elStatus, 'nestStatus', nestStatus);
      
      let nestState = '';
      if (nestStatus.type === 'error') {
        nestError = nestStatus.status;
      }
      
      if (typeof elStatus === 'object') {
        if (!elStatus.electron_id || elStatus.charging === 'Not Charging') {
          nestState = 'error';
        } else if (elStatus.soc > 90) {
          nestState = 'success';
        }
        elStatus.nestError = nestError;
      } 

      if (nestStatus.type === 'error') {
        nestState = 'error';
      }
      if (accessError) {
        nestState = 'error';
      }
      if (nestState === 'error') {
         problems.push(
          <p key={i} >
            <span style={{color: '#000' , fontWeight: 'bold'}}>{`Nest #${k}. `}</span> 
            <span style={{color: 'red' , display: 'block'}}>
            { typeof elStatus === 'object' ?
              <React.Fragment>
                {elStatus.electron_id && nestStatus.code === 'EO' ? 
                  'Nest EO. Open and close the nest if its still EO - mark to Repair '
                : null }
                { nestStatus.code === 'BO' ? 
                  'Nest BO. Try diferent Electron. Open and close the nest. if its still BO - mark to Repair '
                : null }
                {!elStatus.electron_id ? 
                  'No Electron ID. Try Electron on other Nest (if it didnt work mark to Repair)'
                : null }
                {elStatus.charging === 'Not Charging' ? 
                  'Not Charging. Try Electron on other Nest (if it didnt work mark to Repair)'
                : null }
              </React.Fragment>
            : <React.Fragment>
                {nestStatus.code === 'EO' ? 
                  'Nest EO. Open and close the nest if its still EO - mark to Repair '
                : null }
                { nestStatus.code === 'BO' ? 
                  'Nest BO. Try diferent Electron and open and close the nest. if its still BO - mark to Repair '
                : null }
              </React.Fragment> }
              </span>
              <span style={{marginTop: '15px', display: 'block'}}>
                <Button style={buttonStyle} onClick={e => this.sendCommand(`release_${k}`)}>
                  Release to Test
                </Button>
                { isCellularSupernova ? <Button style={buttonStyle} onClick={e => this.sendCommand(`release_pack_${k}`)}>
                  Release to Pack
                </Button> : null }
                <Button style={buttonStyle} onClick={e => this.sendCommand(`open_nest_${k}`)}>
                  Open
                </Button>
                <Button style={buttonStyle} onClick={e => this.sendCommand(`close_nest_${k}`)}>
                  Close
                </Button>
                <Button style={buttonStyle} onClick={e => this.sendCommand(`red_color_${k}`)}>
                  Red
                </Button>
                <Button style={buttonStyle} onClick={e => this.sendCommand(`blue_color_${k}`)}>
                  Blue
                </Button>
                <Button style={buttonStyle} onClick={e => this.sendCommand(`green_color_${k}`)}>
                  Green
                </Button>
              </span>
            </p>
          );
      }
    });
    if (problems.length) {
      return (
        <Card title="Issues" style={{ marginBottom: '8px' }}>{problems}</Card>
      )
    }
    return null;
  }
  render() {
    const {
      isFetching,
      objId,
      type,
      status,
      actualStatus,
      visibleNotes,
      sessionId,
      visiblePrintLabel,
      activeNest
    } = this.state;
    
    const isCellularSupernova = objId && objId.length > 2 ? objId.substring(0, 2) === 'SC' : false;
    return (
      <Content className="fill-bg-table" style={{maxWidth: '700px', 'margin': '0 auto'}}>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          debounce={250}
          timeout={1000 * 60 * 2} />
        <h2 className="table-title">{objId} <Button size="small" onClick={this.startInventory}>
            Get Fresh Data
        </Button></h2>
        <Spin spinning={ isFetching } >
            {
              type === 'supernova' ? 
              <React.Fragment>
                {
                  status === 'online' ?
                  <React.Fragment>
                    <Card title="Supernova" style={cardStyle}>
                      {this.getSupenovasDescription()}
                      <div className="qcSupernova">
                        {this.renderNests()}
                      </div>
                    </Card>
                    {activeNest ? <Card title={`Nest #${activeNest}`} style={cardStyle}>
                      <Button style={buttonStyle} onClick={e => this.sendCommand(`release_${activeNest}`)}>
                        Release to Test
                      </Button>
                      { isCellularSupernova ? <Button style={buttonStyle} onClick={e => this.sendCommand(`release_pack_${activeNest}`)}>
                        Release to Pack
                      </Button> : null }
                      <Button style={buttonStyle} onClick={e => this.sendCommand(`open_nest_${activeNest}`)}>
                        Open
                      </Button>
                      <Button style={buttonStyle} onClick={e => this.sendCommand(`close_nest_${activeNest}`)}>
                        Close
                      </Button>
                      <Button style={buttonStyle} onClick={e => this.sendCommand(`red_color_${activeNest}`)}>
                        Red
                      </Button>
                      <Button style={buttonStyle} onClick={e => this.sendCommand(`blue_color_${activeNest}`)}>
                        Blue
                      </Button>
                      <Button style={buttonStyle} onClick={e => this.sendCommand(`green_color_${activeNest}`)}>
                        Green
                      </Button>
                    </Card> : null }
                    {this.renderProblems()}
                    <Card title="All nests" style={cardStyle}>
                      <Button style={buttonStyle} onClick={e => this.sendCommand('red_color_all')}>
                        Red
                      </Button>
                      <Button style={buttonStyle} onClick={e => this.sendCommand('blue_color_all')}>
                        Blue
                      </Button>
                      <Button style={buttonStyle} onClick={e => this.sendCommand('green_color_all')}>
                        Green
                      </Button>
                      <Button style={buttonStyle} onClick={e => this.sendCommand('alarm_on')}>
                        Alarm ON
                      </Button>
                      <Button style={buttonStyle} onClick={e => this.sendCommand('alarm_off')}>
                        Alarm OFF
                      </Button>
                      { isCellularSupernova ? <Button style={buttonStyle} onClick={this.releaseToPackAll}>
                        Release to Pack
                      </Button> : null }
                    </Card>
                    <Card title="Actions" style={cardStyle}>
                      <Button style={buttonStyle} type="primary" onClick={this.showNotes}>
                        Add comment
                      </Button>
                      <Button style={buttonStyle} type="primary" onClick={this.showPrintLabel}>
                        Print Label
                      </Button>
                      { visiblePrintLabel ? <PrintLabel visible={visiblePrintLabel} handleClose={this.hidePrintLabel} sourceId={objId} /> : null }
                      { visibleNotes ? <Notes visible={visibleNotes} handleClose={this.hideNotes} sessionId={sessionId} sourceId={objId} source="supernova" /> : null }
                      { actualStatus === 'factory' ? <Button style={buttonStyle} type="primary" onClick={this.fullyTested}>
                        Mark as fully tested
                      </Button> : null }
                    </Card>
                  </React.Fragment>
                  : <Alert message="Supernova is offline" type="warning" style={{marginTop: '20px'}} />
                }
                
              </React.Fragment>
              : null 
            }
        </Spin>
      </Content>
    )
  }
};

export default Testing;