import React from 'react';
import { Modal, Button, Radio } from 'antd';
import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';

class PrintLabel extends React.Component {
  state = {
  	powerSupply: 'Empty'
  }
  componentDidMount() {
  }
  onChangePower = e => {
    console.log('radio3 checked', e.target.value);
    this.setState({
      powerSupply: e.target.value,
    });
  };
  render() {
  	const { powerSupply } = this.state;
    const { visible, sourceId } = this.props;
    return (
      <Modal
        className="nocardPadding"
        style={{ top: 20 }}
        width={600}
        visible={ visible }
        title={`Print label for ${sourceId}`}
        onOk={this.props.handleClose}
        onCancel={this.props.handleClose}
        footer={[
          <Button key="submit" type="primary" onClick={this.props.handleClose}>
            Ok
          </Button>,
        ]}
      >
      <div style={{paddingBottom: '16px'}}>
	      <h3>Power Supply</h3>
	      <Radio.Group value={powerSupply} onChange={this.onChangePower}>
	        <Radio.Button value="10A">10A</Radio.Button>
	        <Radio.Button value="20A">20A</Radio.Button>
	        <Radio.Button value="Empty">Empty</Radio.Button>
	      </Radio.Group>
			</div>
      <div id="printJS-form" ref={el => (this.componentRef = el)} style={{'padding': '30px'}}>
      	<div style={{paddingBottom: '16px'}}>
      		<QRCode value={sourceId} size={180} />
      	</div>
      	<div style={{paddingBottom: '16px', 'fontSize': '18px', 'color': '#000'}}>
      		ID: <b>{sourceId}</b><br/>
      		Power Supply: <b>{powerSupply}</b>
      	</div>
			</div>
			
			<ReactToPrint
        trigger={() => <Button type="primary">
				 		Print this out!
				 </Button>}
        content={() => this.componentRef}
      />
      </Modal>
    )
  }
};

export default PrintLabel;