import React from 'react';
import { Redirect } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper/AppWrapper';
// import Loading from 'components/Loading';

import Auth from './Auth';
import fetchJSON from './fetchJSON';

/**
 * when entering the page to check if the user has access to them
 */ 
const checkRole = (next) => {
	// create an AJAX request
	fetchJSON('/api/v2/auth/role', {
		method: 'get',
	}).then(data => {
		console.log('data', data);
		if (data.success) return next(data.role);
		if (data.role === 'wrongToken') return next(data.role);
		return next(false);
	}).catch((error) => {
		if (error.status === 500) {
			return next('error500');
		} else {
			return next(false);
		}
	});
};


export const checkAccessWrap = (allowedRole, Component) => {
  class AuthenticatedComponent extends React.Component {
		state = {
			isLoggedIn: false,
			role: ''
		}
    UNSAFE_componentWillMount() {
      this.checkAuth();
    }
    checkAuth() {
    	checkRole((role) => {
				const location = this.props.location;
				const redirect = location.pathname + location.search;				
				if (allowedRole.indexOf(role) > -1) {
					this.setState({
						isLoggedIn: true,
						role: role
					});
				} else if (role === 'error500') {
					this.props.history.push(`/error500?redirect=${redirect}`);
				} else if (role === 'wrongToken') {
					this.props.history.push(`/logout`);
				} else if (role) {
					this.props.history.push('/');
				} else {
					this.props.history.push(`/login?redirect=${redirect}`);
				}
			})
    }
    render() {
    	const { role } = this.state;
      return this.state.isLoggedIn
        ? <AppWrapper role={role}><Component { ...this.props } /></AppWrapper>
        : null ;
    }
  }
	return AuthenticatedComponent;
}

export const checkLogin = (redirectTo, next) => {
	if (Auth.isUserAuthenticated()) {
		return ( <Redirect to={{ pathname: redirectTo }} /> );
	} else {
		return next();
	}
};

export default checkAccessWrap;
