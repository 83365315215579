import React from 'react';
import { Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import Auth from 'services/utils/Auth';

import { MenuContainer } from './components/Breadcrumb';

import './styles.less';

import Logo from 'components/images/logo.svg';

const { Header, Content, Footer } = Layout;

class AppWrapper extends React.Component {
  render() {
    const { role } = this.props;
    const isAuthenticated = Auth.isUserAuthenticated();

    let menu = {};

    menu = {
      '/': 'Search',
      '/logout': 'Logout'
    };
    return (
      <div>
    	{ isAuthenticated ? <Layout className="layout">
        <Header>
          <div className="header-logo">
            <img className="header-logo-img" src={Logo} alt="Logo" />
          </div>
          <MenuContainer role={role} routes={menu} />
        </Header>
        <Content style={{ padding: '16px 16px 0 16px' }}>
          { this.props.children }
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Electron-to-Go Controller
        </Footer>
      </Layout> : <Redirect to='/login' /> }
      </div>
    )
  }
};

export default AppWrapper;