/**
 * Auth to identify the user 
 * checks for a token in the local store
 */
import Cookies from 'js-cookie';

// import static env
import Settings from 'services/config/Settings';

class Authenticate {
  constructor(n){
    this.n = n;
  }
  /**
   * Save the token in Local Storage
   *
   * @param {string} token
   */
  static loginUser(token, userId = null) {
    localStorage.setItem('userToken', token);
    Cookies.set('userToken', token, { expires: 180 });
		if (userId) {
      if (Settings.analytics.heap !== 'null') {
        /* eslint-disable */
        try {
          heap.identify(userId);
        } catch(e) { }
        /* eslint-enable */
      }
		}
  }

  /**
   * Checking user authorization
   *
   * @returns {boolean}
   */
  static isUserAuthenticated() {
    return Cookies.get('userToken') !== undefined || localStorage.getItem('userToken') !== null;
  }

  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  static deauthenticateUser() {
    localStorage.removeItem('userToken');
    Cookies.remove('userToken');
    if (Settings.analytics.heap !== 'null') {
      /* eslint-disable */
      try {
        heap.resetIdentity();
      } catch(e) { }
      /* eslint-enable */
    }
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */

  static getToken() {
    // return Cookies.get('token');
    if (Cookies.get('userToken') !== undefined) {
      return Cookies.get('userToken');
    }
    if (localStorage.getItem('userToken') !== null) {
      return localStorage.getItem('userToken');
    }
    return null;
  }
  

}

export default Authenticate;