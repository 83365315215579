import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Breadcrumb, Menu } from 'antd';
import Route from 'route-parser';

const isFunction = value => typeof value === 'function';

const getPathTokens = (pathname) => {
  const paths = ['/'];

  if (pathname === '/') return paths;

  pathname.split('/').reduce((prev, curr) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });

  return paths;
};

const getRouteMatch = (routes, path) => {
  return Object.keys(routes)
    .map(key => {
      const params = new Route(key).match(path);
      return {
        didMatch: params !== false,
        params,
        key
      };
    })
    .filter(item => item.didMatch)[0];
}

const getBreadcrumbs = ({ routes, match, location }) => {
  const pathTokens = getPathTokens(location.pathname);
  return pathTokens.map((path, i) => {
    const routeMatch = getRouteMatch(routes, path);
    const routeValue = routeMatch ? routes[routeMatch.key] : null;
    const name = isFunction(routeValue)
      ? routeValue(routeMatch.params)
      : routeValue;
    return { name, path };
  });
}

const Breadcrumbs = ({ routes, match, location }) => {
  const breadcrumbs = getBreadcrumbs({ routes, match, location });
  return (
    <Breadcrumb style={{ margin: '16px 0' }}>
      {breadcrumbs.map((breadcrumb, i) =>
        <Breadcrumb.Item key={breadcrumb.path}>
          {i < breadcrumbs.length - 1 ? <Link to={breadcrumb.path}>
            {breadcrumb.name}
          </Link> : breadcrumb.name }
        </Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
}

const getMenuItems = ({ routes, match, location }) => {
  const menus = [];
  let defaultIndex = 0;
  Object.keys(routes).forEach((path, index) => {
    let active = false;
    if (location.pathname === '/' && path === '/') {
      active = true;
    } else {
      active = path === '/' ? false : location.pathname.match(path) !== null;
    }
    if (active) defaultIndex = index;
    menus.push({
      active: active,
      path: path,
      name: routes[path]
    })
  
  })
  return { items: menus, defaultIndex };
}
const Menus = ({ role, routes, match, location }) => {
  const menu = getMenuItems({ routes, match, location });
  console.log('menu', menu);
  const renderItems = () => menu.items.map((item, key) => {
    return <Menu.Item key={key}><Link to={item.path}>{item.name}</Link></Menu.Item>;
  });
  return (
    <Menu
      theme="dark"
      mode="horizontal"
      defaultSelectedKeys={[menu.defaultIndex.toString()]}
      style={{ lineHeight: '64px' }}
    >
      { renderItems() }
    </Menu>
  );
}
export const MenuContainer = withRouter(Menus);

export default withRouter(Breadcrumbs);